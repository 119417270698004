import axios from "axios";
import { createContext, ReactNode, useState } from "react";
import useSWR from "swr";
import { fetcher } from "utils-react";

import { ISWR } from "../@types";
import { useCurrentUser } from "../hooks/useCurrentUser";
import {
  atomicFetcher,
  bloksFetcher,
  secondAtomicFetcher,
  SWRCacheKeyGetters,
} from "../services/swr";

interface IFetchProvider {
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FetchContextProps {
  cannonsLevelCap: any[];
  cannonsLevelCapIsLoading: boolean;
  boostsLevelCap: any[];
  boostsLevelCapIsLoading: boolean;
  userInventory: any[];
  isLoadingUserInventory: boolean;
  levelCap: any;
  levelCapIsLoading: boolean;
  levelCapError: any;
  zombieTokenBalanceIsLoading: boolean;
  zombieTokenBalance: any;
  zombieTemplates: any;
  zombieTemplatesIsLoading: boolean;
  zombieRankingLeaderboard: any[];
  setLeaderboardPeriod: (period: { dateStart: Date; dateEnd: Date }) => void;
  countRankedGames: {
    gamesCount: number;
  };
  countRankedGamesIsLoading: boolean;
  petsData: any;
  petsDataIsLoading: boolean;
}
// https://aa.neftyblocks.com/atomicassets/v1/assets?collection_name= or https://test.wax.api.atomicassets.io/atomicassets/v1/assets?collection_name=

export const FetchContext = createContext({} as FetchContextProps);

export const FetchProvider = ({ children }: IFetchProvider) => {
  const { currentUser } = useCurrentUser();

  const [leaderboardPeriod, setLeaderboardPeriod] = useState({
    dateStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const { data: userInventory, isLoading: isLoadingUserInventory }: ISWR =
    useSWR(
      SWRCacheKeyGetters.getUserInventory(currentUser),
      secondAtomicFetcher,
    );
  const {
    data: levelCap,
    isLoading: levelCapIsLoading,
    error: levelCapError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getLevelCapRequirements("pets"),
    bloksFetcher,
  );
  const {
    data: cannonsLevelCap,
    isLoading: cannonsLevelCapIsLoading,
    error: cannonsLevelCapError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getLevelCapRequirements("cannons"),
    bloksFetcher,
  );
  const {
    data: boostsLevelCap,
    isLoading: boostsLevelCapIsLoading,
    error: boostsLevelCapError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getLevelCapRequirements("amulets"),
    bloksFetcher,
  );
  const {
    data: zombieTokenBalance,
    isLoading: zombieTokenBalanceIsLoading,
    error: zombieTokenBalanceError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getZombieTokenBalance(currentUser),
    bloksFetcher,
  );
  const {
    data: zombieTemplates,
    isLoading: zombieTemplatesIsLoading,
    error: zombieTemplatesError,
  }: ISWR = useSWR(SWRCacheKeyGetters.getZombieTemplates(), atomicFetcher);

  const {
    data: zombieRankingLeaderboard,
    isLoading: zombieRankingLeaderboardIsLoading,
    error: zombieRankingLeaderboardError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getZombieRankingLeaderboard(leaderboardPeriod),
    fetcher,
  );

  const {
    data: countRankedGames,
    isLoading: countRankedGamesIsLoading,
    error: countRankedGamesError,
  }: ISWR = useSWR(
    SWRCacheKeyGetters.getCountRankedGames(currentUser),
    fetcher,
  );

  const {
    data: petsData,
    isLoading: petsDataIsLoading,
    error: petsDataError,
  }: ISWR = useSWR(SWRCacheKeyGetters.getPetsData(currentUser), fetcher);

  return (
    <FetchContext.Provider
      value={{
        cannonsLevelCap,
        cannonsLevelCapIsLoading,
        boostsLevelCap,
        boostsLevelCapIsLoading,
        userInventory,
        isLoadingUserInventory,
        levelCap,
        levelCapIsLoading,
        levelCapError,
        zombieTokenBalanceIsLoading,
        zombieTokenBalance,
        zombieTemplates,
        zombieTemplatesIsLoading,
        zombieRankingLeaderboard,
        setLeaderboardPeriod,
        countRankedGames,
        countRankedGamesIsLoading,
        petsData,
        petsDataIsLoading,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};
