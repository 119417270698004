import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

import { IFormatedAsset } from "../@types";
import { IGameStages, ISelectedCards } from "../@types/game";
import { ISocketMatchFound, ISocketQueueDetails } from "../@types/socket";

interface IGameProvider {
  children: ReactNode;
}

interface GameContextProps {
  selectedCards: ISelectedCards | null;
  setSelectedCards: (nft, type) => void;
  stage: IGameStages;
  setStage: Dispatch<SetStateAction<IGameStages>>;
  roundData: ISocketMatchFound | null;
  setRoundData: Dispatch<SetStateAction<ISocketMatchFound | null>>;
  trainSelectedItem: any | null;
  setTrainSelectedItem: Dispatch<SetStateAction<any | null>>;
  levelUpSelectedItem: any | null;
  setLevelUpSelectedItem: Dispatch<SetStateAction<any | null>>;
  gameLoading: boolean;
  setRawSelectedCards: Dispatch<SetStateAction<ISelectedCards | null>>;
  queueDetails: ISocketQueueDetails[];
  setQueueDetails: Dispatch<SetStateAction<ISocketQueueDetails[]>>;
}

export const GameContext = createContext({} as GameContextProps);

export const GameProvider = ({ children }: IGameProvider) => {
  const [gameLoading, setGameLoading] = useState(false);
  const [queueDetails, setQueueDetails] = useState<any[]>([]);
  const [stage, setStage] = useState<IGameStages>("selection");
  const [roundData, setRoundData] = useState<ISocketMatchFound | null>(null);
  const [trainSelectedItem, setTrainSelectedItem] = useState<any>(null);
  const [levelUpSelectedItem, setLevelUpSelectedItem] = useState<any>(null);
  const [selectedCards, setRawSelectedCards] = useState<ISelectedCards | null>({
    pet: null,
    cannon: null,
    boost: null,
  });

  const setSelectedCards = (nft, type) => {
    const data = nft.data;
    const { img, ...attributes } = data;

    const formattedNft: Partial<IFormatedAsset> = {
      assetId: nft.assetId,
      templateId: nft.templateId,
      img,
      type,
      attributes,
      data,
    };

    setRawSelectedCards(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState));

      if (newState[type]?.assetId !== formattedNft?.assetId) {
        newState[type] = formattedNft;
      } else {
        newState[type] = null;
      }

      return newState;
    });
  };

  return (
    <GameContext.Provider
      value={{
        selectedCards,
        setSelectedCards,
        stage,
        setStage,
        roundData,
        setRoundData,
        trainSelectedItem,
        setTrainSelectedItem,
        levelUpSelectedItem,
        setLevelUpSelectedItem,
        gameLoading,
        setRawSelectedCards,
        queueDetails,
        setQueueDetails,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
