import axios from "axios";
import { JsonRpc } from "eosjs";
import { mutate } from "swr";

import { arrayEquals, delay } from "../utils";
import { apiURL, config } from "../utils/constants";
import { rede } from "../utils/rede";
import { AssetFormatter } from "./formatter";

const collection =
  rede === "mainet"
    ? config.collectionNameMainet
    : config.collectionNameTestnet;

const api = rede === "mainet" ? config.atomicMainet : config.atomicTestnet;

const url = api + collection;
export class SWRCacheKeyGetters {
  static getUserInventory(account) {
    return `${url}&owner=${account}`;
  }

  static getZombieTemplates() {
    return `${url.replace("/assets?", "/templates?")}`;
  }

  static getLevelCapRequirements(type) {
    return `levelcap-${type}`;
  }

  static getZombieTokenBalance(account) {
    const contractToken = rede === "mainet" ? "zombiecointk" : "zombiecoinst";
    if (account)
      return `zombietokenBalance-accounts-${account}-${contractToken}-ZBC`;
  }

  static getZombieRankingLeaderboard(period: {
    dateStart: Date;
    dateEnd: Date;
  }) {
    const isTheSameDay =
      period.dateStart.getTime() === period.dateEnd.getTime();
    if (isTheSameDay) {
      period.dateEnd.setDate(period.dateEnd.getDate() + 1);
    }
    return `${apiURL}/ranking?dateStart=${period.dateStart.toISOString()}&dateEnd=${period.dateEnd.toISOString()}`;
  }

  static getCountRankedGames(account: string) {
    return `${apiURL}/player/ranked/${account}`;
  }

  static getPetsData(account: string) {
    return `${apiURL}/pets/${account}`;
  }
}

export const bloksFetcher = async (dataRequest: string) => {
  const rpc = new JsonRpc(
    rede === "mainet" ? config.bloksMainet : config.bloksTestnet,
  );

  const options = dataRequest.split("-");

  const isTokenFetch = options[0] === "zombietokenBalance";

  const bounds = isTokenFetch && {
    lower_bound: options[4],
    upper_bound: options[4],
  };

  const dataNtfsRequest = {
    json: true,
    code:
      options[3] ||
      (rede === "mainet"
        ? config.contractAccountMainet
        : config.contractAccountTestnet),
    scope:
      options[2] ||
      (rede === "mainet"
        ? config.contractAccountMainet
        : config.contractAccountTestnet),
    table: options[1],
    ...bounds,
    key_type: `i64`,
    limit: 1000,
  };

  const response: { rows: any } = await rpc?.get_table_rows(dataNtfsRequest);

  return response.rows;
};

export const atomicFetcher = async (url: string) => {
  let nonce = Date.now();
  let pageNumber = 1;

  let data = [];
  while (true) {
    const response = await axios.get(
      `${url}&limit=1000&page=${pageNumber}&nonce=${nonce}`,
    );
    data = [...data, ...response.data.data] as any;
    pageNumber++;
    nonce = Date.now() + 1;
    if (response.data.data.length < 1000) {
      break;
    }
  }

  return data;
};

export const secondAtomicFetcher = async (url: string, account?: any) => {
  try {
    let nonce = Date.now();
    let pageNumber = 1;

    let data = [];
    while (true) {
      const response = await axios.get(
        `${url}&limit=1000&page=${pageNumber}&nonce=${nonce}&template_blacklist=621776`,
      );

      if (response.data.data) {
        data = [...data, ...response.data.data] as any;
        pageNumber++;
        nonce = Date.now() + 1;
        if (response.data.data.length < 1000) {
          break;
        }
      }
    }

    const response = AssetFormatter.formatAsset(data, account || "");
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const SWRTimedCacheOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
  dedupingInterval: Number(process.env.swrTimedCacheExpiration),
};

export const mutateApiData = async (
  key: any,
  currentData: any,
  fetcher: (url: string) => Promise<any>,
  retries = 1,
  time = 2,
) => {
  if (retries === 11) {
    return "";
  }

  const data = await fetcher(key);

  const isEqual = arrayEquals(data, currentData);

  if (isEqual) {
    if (retries <= 6) {
      await delay(10000);
      mutateApiData(key, currentData, fetcher, retries + 1, time);
    } else {
      await delay(10000 * time);
      mutateApiData(key, currentData, fetcher, retries + 1, time + 1);
    }

    return true;
  }

  mutate(key, data);
  return false;
};
