import { useState } from "react";
import DatePicker from "react-datepicker";
import { BsCalendar3 } from "react-icons/bs";

import { useFetch } from "../../../hooks/useFetch";
import { convertGMTtoDateInUTC } from "../../../utils";
import Loader from "../../Loader";
const OPTIONS_RANKING = [
  {
    value: "all",
    label: "All Categories",
  },
  {
    value: "bronze",
    label: "Bronze",
  },
  {
    value: "silver",
    label: "Silver",
  },
  {
    value: "gold",
    label: "Gold",
  },
];

export const Ranking = () => {
  const { zombieRankingLeaderboard, setLeaderboardPeriod } = useFetch();
  const [ranking, setRanking] = useState("all");

  const [dateStart, setDateStart] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0),
  );

  const [dateEnd, setDateEnd] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  );

  const handleLeaderboardPeriod = (period: {
    dateStart: Date;
    dateEnd: Date;
  }) => {
    const dayTmp = 24 * 3600 * 1000;

    const utcPeriod: {
      dateStart: Date;
      dateEnd: Date;
    } = {
      dateStart: convertGMTtoDateInUTC(period.dateStart.getTime()),
      dateEnd: convertGMTtoDateInUTC(period.dateEnd.getTime() + dayTmp - 1000),
    };

    setLeaderboardPeriod(utcPeriod);
  };

  const filteredZombieRankingLeaderboard = zombieRankingLeaderboard?.filter(
    data => {
      if (ranking === "all") return true;
      return data.rank === ranking;
    },
  );

  const tableStructureStyle =
    "!w-[550px] min-w-[100%] md:!w-[950px] md:justify-around md:gap-0 justify-start gap-8 flex justify-start border-b-2 md:w-auto";

  const tableCellWidth = {
    lg: "min-w-[100px] max-w-[100px]",
    md: "min-w-[78px] max-w-[78px]",
    sm: "min-w-[50px] max-w-[50px]",
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <section className="mb-10 mt-10 flex w-full flex-col items-center justify-center md:w-[100%] md:min-w-[405px] ">
      <article className={`flex flex-col items-center gap-2`}>
        <h4 className="font-impact w-full text-center text-4xl font-normal uppercase text-white">
          {"PvP Leaderboard"}
        </h4>
      </article>
      <section className="gold-border mt-10 w-[350px] items-center justify-center rounded-[15px] border-2 bg-basket_blue-950 p-2 text-left md:w-[700px] md:p-6 xl:w-[1200px]">
        <div className={`flex flex-col items-center gap-5 xl:flex-row`}>
          <aside className="md mb-5 flex flex-col ">
            <span className="font-impact font-medium uppercase text-white md:text-[20px]">
              Period:
            </span>
            <div className="flex flex-col items-center gap-2 md:flex-row">
              <BsCalendar3 className="hidden text-4xl text-white md:block" />
              <div className="font-impact font-medium uppercase text-white md:text-[20px]">
                <DatePicker
                  className={`mb-2 rounded-lg bg-[#3B3B3B] p-1.5 outline-none`}
                  selected={new Date(dateStart.toISOString())}
                  onChange={date => date && setDateStart(date)}
                />
                {" - "}
                <DatePicker
                  className={`rounded-lg bg-[#3B3B3B] p-1.5 outline-none`}
                  selected={new Date(dateEnd.toISOString())}
                  onChange={date => date && setDateEnd(date)}
                />
              </div>
              <button
                className="gamebtn"
                onClick={() => {
                  handleLeaderboardPeriod({
                    dateStart: new Date(dateStart),
                    dateEnd: new Date(dateEnd),
                  });
                }}
              >
                Filter
              </button>
            </div>
          </aside>
          {/* <aside className="mb-5 flex flex-col">
            <span className="font-impact font-medium uppercase text-white md:text-[20px]">
              Filter By Category:
            </span>
            <select
              className={`rounded-lg bg-[#3B3B3B] p-1.5 outline-none`}
              onChange={e => setRanking(e.target.value)}
            >
              {OPTIONS_RANKING.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </aside> */}
        </div>
        <div className="overflow-x-auto overflow-y-hidden">
          <div className={`my-5 flex ${tableStructureStyle}`}>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Position
            </span>
            <span
              className={`font-impact ${tableCellWidth.lg} font-medium uppercase text-white md:text-xl`}
            >
              Player
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Points
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Wins
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Losses
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Draw
            </span>
            <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Total Matches
            </span>
            {/* <span
              className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-xl`}
            >
              Category:
            </span> */}
          </div>
          {!zombieRankingLeaderboard && <Loader />}
          {zombieRankingLeaderboard && zombieRankingLeaderboard.length > 0 ? (
            filteredZombieRankingLeaderboard.map((data, index) => {
              return (
                <div
                  key={index}
                  className={`mb-5 mt-5 flex  ${tableStructureStyle}`}
                >
                  <span
                    className={`font-impact ${
                      tableCellWidth.sm
                    } font-medium uppercase md:text-lg ${
                      index < 20 ? "text-yellow-300" : "text-white"
                    }`}
                  >
                    #{index + 1}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.lg} cursor-copy font-medium lowercase text-white md:text-lg`}
                    onClick={() => copyToClipboard(data.player)}
                  >
                    {data.player}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
                  >
                    {data.points}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
                  >
                    {data.wonGames}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
                  >
                    {data.lossGames || 0}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
                  >
                    {data.drawGames}
                  </span>
                  <span
                    className={`font-impact ${tableCellWidth.sm} font-medium uppercase text-white md:text-lg`}
                  >
                    {data.totalGames}
                  </span>
                  {/* <span
                    className={`font-impact ${
                      tableCellWidth.sm
                    } font-medium uppercase  md:text-lg ${
                      index <= 20 ? "text-yellow-300" : "text-white"
                    }`}
                  >
                    {data.rank}
                  </span> */}
                </div>
              );
            })
          ) : (
            <div className="flex h-[300px] items-center justify-center">
              <span className="text-2xl text-[#eee]">No results found.</span>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};
